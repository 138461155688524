import { toast } from 'react-toastify'
import Big from 'big.js'

import CONFIG from '@/helpers/config'

import { _handleContractResponse } from './_utils'
import { TezosToolkit } from '@taquito/taquito'

export async function mint(data: {
  tezos: TezosToolkit
  amount: number
  signature: string
  price: number
  onSuccess?: Function
  onError?: Function
  onException?: Function
}): Promise<void> {
  try {
    const contract = await data.tezos.wallet.at(
      CONFIG.CONTRACTS.DIPLOMAT_CROWDSALE
    )

    const toastId = 'diplomat-mint'

    toast.update(toastId, {
      render: 'Please confirm the transaction on your wallet',
      type: toast.TYPE.INFO,
    })

    const op = await contract.methods.mint(data.amount, data.signature).send({
      amount: Big(data.amount).mul(data.price).toNumber(),
      mutez: true,
    })

    toast.update(toastId, {
      render: 'Confirming your transaction',
      type: toast.TYPE.INFO,
    })

    await _handleContractResponse(
      op,
      data.onSuccess,
      data.onError,
      data.onException
    )
  } catch (error) {
    data.onError?.(error)
  }
}
