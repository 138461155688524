import commoner from '@/assets/class-badge-commoner.png'
import harvester from '@/assets/class-badge-harvester.png'
import magnate from '@/assets/class-badge-magnate.png'
import statesman from '@/assets/class-badge-statesman.png'
import royal from '@/assets/class-badge-royal.png'

import './ClassDistribution.scss'

const data = [
  {
    name: 'commoner',
    percentage: 50,
    image: commoner,
  },
  {
    name: 'harvester',
    percentage: 20,
    image: harvester,
  },
  {
    name: 'magnate',
    percentage: 15,

    image: magnate,
  },
  {
    name: 'statesman',
    percentage: 10,
    image: statesman,
  },
  {
    name: 'royal',
    percentage: 5,

    image: royal,
  },
]

function ClassDistribution() {
  return (
    <div className="class-distribution">
      <h1>CLASS DISTRIBUTION</h1>
      <p className="description">
        Another aspect of rarity will be the class distribution of Diplomats.
        Higher class diplomats have a better chance of succeeding in their
        assigned tasks. Recruiting new diplomats in the future is also based on
        class, as Tezotopia progresses and diplomats die, new diplomats can be
        minted, players will recruit Diplomats based on their class history.
      </p>
      <div className="classes-container">
        {data.map(({ name, percentage, image }) => (
          <div className="class" key={name}>
            <img src={image} alt={`Class ${name}`} />
            <p>
              <span>{name}</span>
              <span>{percentage}%</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ClassDistribution
