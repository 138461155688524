import { useLayoutEffect } from 'react'
import styles from './Splash.module.scss'
import { Modal } from '@/components'
import tezotopiaSplashPng from './tezotopia-splash.png'
import tezotopiaSplashWebP from './tezotopia-splash.webp'
import { useAppDispatch, useAppSelector } from '@/store'
import { toggleSplashScreen } from '@/store/config'

const Splash = ({ onSyncClick, user, submitting }) => {
  const show = useAppSelector((state) => state.config.showSplashScreen)
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    localStorage.setItem('splashScreenVisited', true)
  }, [])

  const handleDismiss = () => {
    dispatch(toggleSplashScreen(false))
  }

  return (
    <Modal isVisible={show} onDismiss={handleDismiss} zIndex={1000}>
      <div className={styles.splashWrapper}>
        <picture className={styles.splashImg}>
          <source srcSet={tezotopiaSplashWebP} type="image/webp" />
          <img src={tezotopiaSplashPng} alt="splash" />
        </picture>
        <section className={styles.links}>
          <a
            href="https://tezotop.io/getting-started/"
            target="_blank"
            rel="noreferrer"
          >
            Getting Started
          </a>
          <a
            href="https://gifgames.substack.com/"
            target="_blank"
            rel="noreferrer"
          >
            Visit our Blog
          </a>
          <a
            href="https://docs.gif.games/tezotopia/battles/non-battle-units/diplomats"
            target="_blank"
            rel="noreferrer"
          >
            Go to Docs
          </a>
        </section>
        {user === false && (
          <button
            className={styles.syncBtn}
            disabled={submitting}
            onClick={() => {
              localStorage.setItem('splashScreenVisited', true)
              onSyncClick()
            }}
          >
            Sync Wallet
          </button>
        )}
      </div>
    </Modal>
  )
}

export default Splash
