import { TransactionWalletOperation } from '@taquito/taquito'

/**
 *
 * @param {*} op - Contarct Operation Object
 * @param {Function} onSuccess
 * @param {Function} onError
 * @param {Function} onException
 */
export async function _handleContractResponse(
  op: TransactionWalletOperation,
  onSuccess?: Function,
  onError?: Function,
  onException?: Function
) {
  const res = await op.confirmation()
  const status = await op.status()

  if (!res.completed) {
    onException?.()
    return
  }

  switch (status) {
    case 'applied':
      onSuccess?.()
      break
    case 'failed':
    case 'backtracked':
      onError?.()
      break
    default:
      onException?.()
      break
  }
}
