import artistImage from '@/assets/artist.jpg'
import arrowImage from '@/assets/arrow.svg'

import './Artist.scss'

function Artist() {
  return (
    <div className="artist">
      <h1>ABOUT THE ARTIST</h1>
      <div>
        <img src={artistImage} alt="artist" />
        <div className="right">
          <h2>IZUL RAHMAN</h2>
          <p>
            Started his professional work in 2013 getting his start creating
            webtoons and game concepts at various animation studios. In 2020
            Rahman decided to work as a freelancer, after realizing his
            speciality and interest in 2D Cell Shade Art.
          </p>
          <p>
            Since then, Rahman has been hard at work in preparation for his own
            art studio launch, 00 Studios in 2022.
          </p>
          <a
            href="https://00studios.id/"
            target="_blank"
            rel="noreferrer"
          >
            View Izul's Website <img src={arrowImage} alt="arrow" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Artist
