import {
  wallet,
  tezos,
  connect,
  disconnectWallet,
  updateNode,
} from '@/helpers/wallet'

export default function useWallet() {
  function isWalletConnected() {
    return !!wallet
  }

  function getTezos() {
    return tezos
  }

  return {
    isWalletConnected,
    wallet,
    tezos,
    connect,
    disconnect: disconnectWallet,
    updateNode,
    getTezos,
  }
}
