import { ContractsLibrary } from '@taquito/contracts-library'
import { TezosToolkit } from '@taquito/taquito'

import data from './contractsData.json'

const contractsLibrary = new ContractsLibrary()

contractsLibrary.addContract(data)

export function attachContractLibrary(tezos: TezosToolkit) {
  tezos.addExtension(contractsLibrary)
}
