import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSplashScreen: !localStorage.getItem('splashScreenVisited'),
  node: null,
  showNodeSelector: false,
  nodes: null,
}

const configSlice = createSlice({
  initialState,
  name: 'config',
  reducers: {
    toggleSplashScreen: (state, action) => {
      state.showSplashScreen = action.payload
    },
    toggleNodeSelector: (state, action) => {
      state.showNodeSelector = action.payload
    },
    updateNodes: (state, action) => {
      state.nodes = action.payload
    },
    updateNode: (state, action) => {
      state.node = action.payload
    },
  },
})

export const {
  toggleSplashScreen,
  toggleNodeSelector,
  updateNodes,
  updateNode,
} = configSlice.actions
export default configSlice.reducer
