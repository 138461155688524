import { bytes2Char } from '@taquito/utils'
import CONFIG from '@/helpers/config'
import { Data } from '@/hooks/data'

import './Provenance.scss'

export default function Provenance({ data }: { data: Data }) {
  return (
    <div className="provenance">
      <h2>PROVENANCE</h2>

      <p>
        <a
          href={CONFIG.BCD_URL + CONFIG.CONTRACTS.DIPLOMAT_CROWDSALE}
          target="_blank"
          rel="noreferrer"
        >
          CROWDSALE CONTRACT
        </a>
        <a href={CONFIG.BCD_URL + data.fa2} target="_blank" rel="noreferrer">
          NFT CONTRACT
        </a>
        <a
          href={
            CONFIG.IPFS_URL +
            bytes2Char(data.provenanceHash).split('ipfs://')[1]
          }
          target="_blank"
          rel="noreferrer"
        >
          PROVENANCE HASH
        </a>
      </p>
    </div>
  )
}
