import { useEffect, useState } from 'react'
import { onAuthStateChanged, Unsubscribe } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import { auth, firestore } from '@/helpers/firebase'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUser } from '@/store/user'
import useWallet from '@/hooks/wallet'

let initialConnectionAttempted = false

export default function useAuth() {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const { connect: connectWallet, disconnect } = useWallet()
  const [isConnecting, setIsConnecting] = useState<Boolean>(true)

  useEffect(() => {
    let authListener: Unsubscribe | undefined
    let userListener: Unsubscribe | undefined

    authListener = onAuthStateChanged(
      auth,
      (user) => {
        if (userListener) {
          userListener()
        }

        if (!user) {
          // No user is signed in.
          setIsConnecting(false)
          dispatch(setUser(false))
          return
        }

        const { uid } = user
        const docRef = doc(firestore, 'users', uid)

        userListener = onSnapshot(docRef, {
          next: (snapshot) => {
            isConnecting && setIsConnecting(false)
            if (!snapshot.exists()) {
              setUser(null)
              return
            }

            dispatch(setUser(snapshot.data()))
          },
          error: (error) => {
            isConnecting && setIsConnecting(false)
            dispatch(setUser(null))
            throw new Error(error.message)
          },
        })
      },
      (error) => {
        isConnecting && setIsConnecting(false)
        dispatch(setUser(null))
        throw new Error(error.message)
      }
    )

    return () => {
      authListener?.()
      userListener?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function signout() {
    disconnect()
  }

  useEffect(() => {
    if (initialConnectionAttempted) {
      return
    }

    if (user === null) {
      return
    }

    connect({ canInitiateConnection: false })
    initialConnectionAttempted = true

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  async function connect(
    options: { canInitiateConnection?: Boolean } = {
      canInitiateConnection: true,
    }
  ) {
    setIsConnecting(true)
    await connectWallet({ user, dispatch }, options)
    setIsConnecting(false)
  }

  return { user, signout, connect, isConnecting }
}
