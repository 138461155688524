import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import {
  getFunctions,
  // connectFunctionsEmulator
} from 'firebase/functions'

import CONFIG from '@/helpers/config'

const firebaseConfig: FirebaseOptions = {
  apiKey: CONFIG.FIREBASE_API_KEY,
  authDomain: CONFIG.FIREBASE_AUTH_DOMAIN,
  projectId: CONFIG.FIREBASE_PROJECT_ID,
  storageBucket: CONFIG.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: CONFIG.FIREBASE_MESSAGING_SENDER_ID,
  appId: CONFIG.FIREBASE_APP_ID,
  measurementId: CONFIG.FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
export default app

// Add all the services here
export const auth = getAuth(app)
export const firestore = getFirestore(app)
export const functions = getFunctions(app)

if (process.env.NODE_ENV === 'development') {
  // connectFunctionsEmulator(functions, 'localhost', 5001)
}
