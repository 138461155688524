import { useState } from 'react'

import questionCircle from '@/assets/question-circle.svg'
import settings from '@/assets/settings.svg'

import useAuth from '@/hooks/auth'
import { truncateAddress } from '@/helpers/wallet'
import { toggleNodeSelector, toggleSplashScreen } from '@/store/config'
import { useAppDispatch } from '@/store'

import './Header.scss'

import logo from '@/assets/logo.png'

function Header() {
  const { user, signout, connect, isConnecting } = useAuth()
  const [hovered, setHovered] = useState(false)

  const dispatch = useAppDispatch()

  function onQuestionIconClick() {
    dispatch(toggleSplashScreen(true))
  }

  return (
    <header className="header">
      <div className="left">
        <a href="https://app.tezotopia.com" target="_blank" rel="noreferrer">
          <img src={logo} alt="Go to Tezotopia" />
        </a>
      </div>
      <div className="right">
        {!user && isConnecting && <button className="connect">Loading</button>}
        {!isConnecting && user === false && (
          <button className="connect" onClick={() => connect()}>
            Connect
          </button>
        )}
        {user && (
          <button
            className="connect"
            id="connected"
            onClick={signout}
            onMouseEnter={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
          >
            {hovered
              ? 'Disconnect'
              : user.username || truncateAddress(user.wallet)}
          </button>
        )}
        <img src={questionCircle} onClick={onQuestionIconClick} alt="Help" />
        <img src={settings} onClick={() => dispatch(toggleNodeSelector(true))} alt="Help" />
      </div>
    </header>
  )
}

export default Header
