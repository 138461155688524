import { useEffect, useState } from 'react'
import { TezosToolkit, BigMapAbstraction } from '@taquito/taquito'
import Big from 'big.js'
import { isMatch } from 'lodash'

import { useAppSelector } from '@/store'
import useWallet from '@/hooks/wallet'
import CONFIG from '@/helpers/config'

let timeout: NodeJS.Timeout

export default function useData() {
  var { getTezos } = useWallet()
  const user = useAppSelector((state) => state.user)
  const [data, setData] = useState<Data | undefined>()
  const [lastFetched, setLastFetched] = useState<number>(Date.now())

  useEffect(() => {
    const data = localStorage.getItem('data')
    if (!data) {
      return
    }

    setData(JSON.parse(data))
  }, [])

  useEffect(() => {
    if (!data) {
      document.body.classList.add('loading')
      return
    }
    document.body.classList.remove('loading')
  }, [data])

  useEffect(() => {
    if (user === null) {
      return
    }

    getData()

    return () => timeout && clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, lastFetched])

  async function getData() {
    const tezos = getTezos()
    console.log(tezos)
    if (!tezos) {
      timeout = setTimeout(async () => await getData(), 1000)
      return
    }

    const newData = await fetchData(tezos, user)
    console.log(newData)
    if (!isMatch(data, newData)) {
      setData(newData)
      localStorage.setItem('data', JSON.stringify(newData))
    }

    timeout = setTimeout(async () => {
      await getData()
    }, 24 * 60 * 60 * 1000)
  }

  return { data, refetch: () => setLastFetched(Date.now()) }
}

async function fetchData(tezos: TezosToolkit, user: any): Promise<Data> {
  const contract = await tezos.contract.at(CONFIG.CONTRACTS.DIPLOMAT_CROWDSALE)
  const storage = await contract.storage<Storage>()

  const data = cleanStorageData(storage)

  const publicSaleActive =
    new Date(data.publicsaleStartTime).getTime() < Date.now()

  if (publicSaleActive) {
    const mintsData = await getMintsData(storage, user)
    return { ...data, ...mintsData }
  }

  const preSaleActive = new Date(data.presaleStartTime).getTime() < Date.now()
  if (preSaleActive) {
    const whitelistData = await getWhitelistData(storage, user)
    return { ...data, ...whitelistData }
  }

  return data
}

async function getWhitelistData(storage: Storage, user: any) {
  const _default = {
    limit: 0,
    minted: 0,
  }
  if (user === false) {
    return _default
  }

  const bigMap = storage.presale_whitelist
  const data = (await bigMap.get(user.wallet)) as {
    minted: Big
    limit: Big
  }

  let _data = {} as {
    minted: number
    limit: number
  }

  if (data) {
    _data.minted = data.minted.toNumber()
    _data.limit = data.limit.toNumber()
  } else {
    _data = _default
  }

  return _data
}

async function getMintsData(storage: Storage, user: any) {
  const _default = {
    counter: 0,
    minted: 0,
  }

  if (user === false) {
    return _default
  }

  const bigMap = storage.mints
  const data = (await bigMap.get(user.wallet)) as {
    counter: Big
    minted: Big
  }

  let _data = {} as {
    counter: number
    minted: number
  }

  if (data) {
    _data.counter = data.counter.toNumber()
    _data.minted = data.minted.toNumber()
  } else {
    _data = _default
  }

  return _data
}

function cleanStorageData(storage: Storage) {
  const fa2 = storage.fa2
  const presaleStartTime = storage.presale_start_time
  const publicsaleStartTime = storage.publicsale_start_time
  const publicsaleEndTime = storage.publicsale_end_time
  const maxMint = storage.max_mint.toNumber()
  const mintLimit = storage.mint_limit.toNumber()
  const price = storage.price.toNumber()
  const provenanceHash = storage.provenance_hash
  const totalMints = storage.total_mints.toNumber()
  const totalSupply = storage.total_supply.toNumber()

  return {
    fa2,
    presaleStartTime,
    publicsaleStartTime,
    publicsaleEndTime,
    maxMint,
    mintLimit,
    price,
    provenanceHash,
    totalMints,
    totalSupply,
  }
}

interface Storage {
  fa2: string
  presale_start_time: string
  publicsale_start_time: string
  publicsale_end_time: string
  max_mint: Big
  mint_limit: Big
  price: Big
  provenance_hash: string
  total_mints: Big
  total_supply: Big
  presale_whitelist: BigMapAbstraction
  mints: BigMapAbstraction
}

export interface Data {
  limit?: number
  counter?: number
  minted?: number
  fa2: string
  presaleStartTime: string
  publicsaleStartTime: string
  publicsaleEndTime: string
  maxMint: number
  mintLimit: number
  price: number
  provenanceHash: string
  totalMints: number
  totalSupply: number
}
