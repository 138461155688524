import { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'

import { Modal } from '@/components'
import { firestore } from '@/helpers/firebase'

import './NodeSelector.scss'
import useWallet from '@/hooks/wallet'
import { useAppDispatch, useAppSelector } from '@/store'
import { toggleNodeSelector, updateNodes } from '@/store/config'
import { toast } from 'react-toastify'

export default function NodeSelector() {
  const dispatch = useAppDispatch()
  const { tezos, updateNode } = useWallet()
  const [customNode, setCustomNode] = useState(null)
  const [currentNode, showNodeSelector, nodes] = useAppSelector(
    ({ config }) => [config.node, config.showNodeSelector, config.nodes]
  )
  const [currentNewNode, setCurrentNewNode] = useState(currentNode)

  useEffect(() => {
    if (currentNode !== currentNewNode) {
      setCurrentNewNode(currentNode)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNode])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    function getNode() {
      if (!tezos) {
        timeout = setTimeout(getNode, 1000)
        return
      }

      const node = localStorage.getItem('node')
      if (node === 'custom') {
        setCurrentNewNode(currentNode || localStorage.getItem('customNode'))
      } else {
        setCurrentNewNode(node)
      }
    }

    getNode()
    return () => clearTimeout(timeout)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const nodesDocRef = doc(firestore, 'config/rpc-nodes')

    return onSnapshot(nodesDocRef, (snapshot) => {
      if (!snapshot.exists) {
        console.error('Node snapshot not found')
        return
      }

      const { nodes } = snapshot.data()
      dispatch(updateNodes(nodes))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function updateRPCNodeOnTezos() {
    let newNode: string
    let isCustomNode: boolean = false

    if (currentNewNode === 'custom') {
      newNode = customNode
      isCustomNode = true
    } else {
      newNode = currentNewNode
    }
    const res = await updateNode(newNode)

    if (!res) {
      toast.error('Your custom node is not responsive. Please try again later.')
      return
    } else {
      toast.success('Node updated successfully!')
    }

    if (isCustomNode) {
      localStorage.setItem('customNode', newNode)
      localStorage.setItem('node', 'custom')
    } else {
      localStorage.setItem('node', newNode)
    }
  }

  return (
    <Modal
      isVisible={showNodeSelector}
      onDismiss={() => dispatch(toggleNodeSelector(false))}
      setVisible={() => dispatch(toggleNodeSelector(true))}
    >
      <div className="node-selector">
        <h2>Node Selector</h2>

        {nodes && (
          <ul>
            {nodes.map((node) => (
              <li
                onClick={() => setCurrentNewNode(node)}
                className={node === currentNewNode ? 'selected' : null}
              >
                <input type="checkbox" checked={node === currentNewNode} />
                <label>{node}</label>
              </li>
            ))}
            <li
              onClick={() => setCurrentNewNode('custom')}
              id="custom"
              className={'custom' === currentNewNode ? 'selected' : null}
            >
              <input type="checkbox" checked={currentNewNode === 'custom'} />
              <input
                type="text"
                placeholder="custom node"
                onChange={(e) => setCustomNode(e.target.value)}
              ></input>
            </li>
          </ul>
        )}
        <button onClick={updateRPCNodeOnTezos}>update</button>
      </div>
    </Modal>
  )
}
