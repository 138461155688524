const CONFIG = {
  // Firebase
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

  // Blockchain
  USER_BALANCE: process.env.REACT_APP_USER_BALANCE,
  TZKT_API: process.env.REACT_APP_TZKT_API,
  NETWORK_TYPE: process.env.REACT_APP_NETWORK_TYPE,
  BCD_URL: process.env.REACT_APP_BCD_URL,
  IPFS_URL: process.env.REACT_APP_IPFS_URL,

  // Contracts
  CONTRACTS: {
    DIPLOMAT_CROWDSALE: process.env
      .REACT_APP_CONTRACTS_DIPLOMAT_CROWDSALE as string,
  },

  CAPTCHA: {
    SITE_KEY: process.env.REACT_APP_CAPTCHA_SITE_KEY as string,
  },
}

export default CONFIG
