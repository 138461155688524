import { mint as _mint } from '@/services/contracts/crowdsale'
import useWallet from '@/hooks/wallet'
import { toast } from 'react-toastify'

export default function useCrowdsaleContract() {
  const { tezos } = useWallet()

  /**
   * @param amount amount of tokens to mint
   * @param signature signature received from backend
   * @param price price per token
   */
  async function mint(
    amount: number,
    signature: string,
    price: number
  ): Promise<void> {
    const toastId = 'diplomat-mint'

    await _mint({
      amount,
      signature,
      tezos,
      price,
      onSuccess: () => {
        toast.update(toastId, {
          render: 'Transaction confirmed',
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        })
      },
      onError: (error?: Error) => {
        toast.update(toastId, {
          render: error ? error.message : 'Failed to mint diplomat.',
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        })
      },
      onException: () => {
        toast.update(toastId, {
          render: 'Failed to mint diplomat. Please try again later.',
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        })
      },
    })
  }

  return {
    mint,
  }
}
