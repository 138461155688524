import './Footer.scss'

import telegram from '@/assets/telegram.svg'
import twitter from '@/assets/twitter.svg'
import instagram from '@/assets/instagram.svg'
import facebook from '@/assets/facebook.svg'
import discord from '@/assets/discord.svg'

function Footer() {
  return (
    <footer className="footer">
      <div className="mask" />
      <p>DIPLOMATS</p>
      <div className="footer-content">
        <a href="https://t.me/gifgames" target="_blank" rel="noreferrer">
          <img src={telegram} alt="telegram" />
        </a>
        <a
          href="https://www.facebook.com/gifgamesxyz/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook" />
        </a>
        <a
          href="https://discord.gg/W2cjSBvMBk"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discord} alt="discord" />
        </a>
        <a
          href="https://www.instagram.com/gifdotgames"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram" />
        </a>
        <a
          href="https://twitter.com/gifdotgames"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a>
      </div>
    </footer>
  )
}

export default Footer
