import { useEffect, useState } from 'react'

import diplomat from '@/assets/diplomat.gif'
import { Data } from '@/hooks/data'

import './Details.scss'

const types = ['day', 'hour', 'min', 'sec']
const timeFormat = Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false,
  maximumFractionDigits: 0,
})

function Details({ data }: { data: Data | undefined }) {
  const [launchData, setLaunchData] = useState<any>()

  useEffect(() => {
    if (!data) {
      return
    }

    const interval = setInterval(() => {
      const launchData = {
        heading: '',
        subHeading: '',
        time: {},
        showTime: false,
        showVeryBigHeading: false,
      }

      const now = Date.now()
      const { presaleStartTime, publicsaleStartTime, publicsaleEndTime } = data

      const _publicsaleStartTime = new Date(publicsaleStartTime)
      const _presaleStartTime = new Date(presaleStartTime)
      const _publicsaleEndTime = new Date(publicsaleEndTime)

      if (now < _presaleStartTime.getTime()) {
        launchData.heading = 'PRESALE STARTS IN :'
        launchData.time = getTimeDifference(_presaleStartTime)
        launchData.showTime = true
      } else if (now < _publicsaleStartTime.getTime()) {
        launchData.heading = 'PUBLIC SALE STARTS IN :'
        launchData.time = getTimeDifference(_publicsaleStartTime)
        launchData.showTime = true
      } else if (now > _publicsaleEndTime.getTime()) {
        launchData.heading = 'PUBLIC SALE HAS ENDED!'
        launchData.showTime = false
        launchData.showVeryBigHeading = true
      } else {
        launchData.heading = 'PUBLIC SALE IS LIVE!'
        launchData.showTime = false
        launchData.showVeryBigHeading = true
      }

      setLaunchData(launchData)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [data])

  return (
    <div className="details">
      {launchData && (
        <div className="countdown">
          <p className="heading">{launchData.heading}</p>
          {launchData?.showTime && (
            <div className="time-container">
              {types.map((t) => {
                return (
                  <p key={t} className="time" id={t}>
                    <span className="number">
                      {timeFormat.format(launchData.time[t])}
                    </span>
                    <span className="type">{t.toUpperCase()}S</span>
                  </p>
                )
              })}
            </div>
          )}
        </div>
      )}
      <div className="main">
        <div className="content stripe-background">
          <p>
            Diplomats of Tezotopia is more than a simple PFP collectible, these
            {' ' + data?.totalSupply} representatives unlock the ability to
            perform various diplomatic actions such as leasing out battle units,
            partitioning Tezotops, and forming alliances.
          </p>
          <p>
            Amongst being randomly generated Diplomats have 4 different
            attributes that make them more or less successful in ther duties:
          </p>
          <ul>
            <li>Charisma</li>
            <li>Class</li>
            <li>Influence</li>
            <li>Health</li>
          </ul>
        </div>
        <div className="image-container">
          <img src={diplomat} alt="diplomat" />
        </div>
      </div>
    </div>
  )
}

export default Details

function getTimeDifference(date: Date): { [key: string]: number } {
  const now = Date.now()
  let ms = date.getTime() - now

  const d = Math.floor(ms / (1000 * 60 * 60 * 24))
  ms = ms - d * (1000 * 60 * 60 * 24)

  const h = Math.floor(ms / (1000 * 60 * 60))
  ms = ms - h * (1000 * 60 * 60)

  const m = Math.floor(ms / (1000 * 60))
  ms = ms - m * (1000 * 60)

  const s = Math.floor(ms / 1000)

  return {
    day: Math.abs(Math.floor(d)),
    hour: Math.abs(Math.floor(h)),
    min: Math.abs(Math.floor(m)),
    sec: Math.abs(Math.floor(s)),
  }
}
