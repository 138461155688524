import { useRef } from 'react'
import styles from './Modal.module.scss'

export default function Modal({
  children,
  isVisible,
  setVisible,
  onDismiss,
  allowDismiss = true,
  zIndex = 999,
}) {
  const contentRef = useRef()
  const backdropRef = useRef()

  // FUNCTIONS
  const dismissModal = () => {
    if (!allowDismiss) return
    if (!setVisible && !onDismiss) return
    contentRef.current.classList.add(styles.dismiss)
    backdropRef.current.classList.add(styles.dismissBackdrop)
    setTimeout(() => {
      if (onDismiss) onDismiss()
      else if (setVisible) setVisible(null)
    }, 200)
  }

  // RENDER
  return isVisible === true ? (
    <div className={styles.modalWrapper} style={{ zIndex }}>
      <div
        className={styles.backdrop}
        ref={backdropRef}
        onClick={dismissModal}
      ></div>
      <div className={styles.content} ref={contentRef}>
        {children}
      </div>
    </div>
  ) : null
}
