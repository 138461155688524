import {
  ColorMode,
  DAppClientOptions,
  NetworkType,
  SigningType,
} from '@airgap/beacon-sdk'
import { TezosToolkit } from '@taquito/taquito'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { b58cencode } from '@taquito/utils'
import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { signInWithCustomToken, signOut } from 'firebase/auth'
import axios from 'axios'

import CONFIG from '@/helpers/config'
import { firestore } from '@/helpers/firebase'
import { auth, functions } from '@/helpers/firebase'
import { attachContractLibrary } from '@/helpers/taquito/taquito'
import { AppDispatch } from '@/store'
import { updateNode as updateNodeInStore } from '@/store/config'

export let tezos: TezosToolkit
export let wallet: BeaconWallet

/**
 * Get hex for the message of payload
 * @param  message Mesage that will be signed by the user
 * @returns hex of message
 */
function getMessageHex(message: string): string {
  const input = Buffer.from(message)
  const prefix = Buffer.from('0501', 'hex')
  const len_bytes = Buffer.from(
    message.length.toString(16).padStart(8, '0'),
    'hex'
  )
  const value = Buffer.concat(
    [prefix, len_bytes, input],
    prefix.length + len_bytes.length + input.length
  )
  return value.toString('hex')
}

/**
 * Create a new beacon wallet instance
 * @param {Object} args
 * @param {String} args.name name for the app
 * @param {String} args.preferredNetwork network to connect wallet to
 * @param {String} args.ColorMode color mode for the wallet
 */
function createNewBeaconWalletInstance(
  config: DAppClientOptions = {
    name: 'Tezotopia',
    preferredNetwork: CONFIG.NETWORK_TYPE as NetworkType,
    colorMode: ColorMode.DARK,
  }
): BeaconWallet {
  const wallet = new BeaconWallet({
    name: config.name,
    preferredNetwork: config.preferredNetwork,
    colorMode: config.colorMode,
  })

  return wallet
}

async function createNewTezosWalletInstance(): Promise<{
  tezos: TezosToolkit
  node: string
}> {
  console.log('Creating new wallet instance')
  console.log('Fetching nodes list')
  let nodes = await getNodeList()

  // Add last node used to list of nodes to try out
  // Because that could be a custom node
  for (const node of nodes) {
    // Make sure the node is responsive
    if (!(await isRPCNodeResponsive(node))) {
      console.log('Failed to connect to node ' + node)
      continue
    }

    // Create a new instance of TezosToolkit with the RPC node
    const tezos = new TezosToolkit(node)
    // attachContractLibrary(tezos)
    return { tezos, node }
  }

  throw new Error('No node was responsive')
}

/**
 * Fetches node list from firestore
 * @returns array of nodes
 */
async function getNodeList(): Promise<string[]> {
  const docRef = doc(firestore, 'config', 'rpc-nodes')
  var snapshot = await getDoc(docRef)

  if (!snapshot.exists()) {
    throw new Error('NPC Node document does not exist')
  }

  const nodes: string[] = snapshot.data().nodes

  return nodes
}

/**
 * Checks if a node is reponsive or not
 * @param  nodeUrl url of the node
 * @returns boolean representing if the node is reponsive or not
 */
async function isRPCNodeResponsive(nodeUrl: string): Promise<boolean> {
  try {
    const response = await axios({
      method: 'get',
      baseURL: nodeUrl,
      timeout: 1000 * 5,
      url: 'chains/main/blocks',
    })
    return response.status === 200
  } catch (error) {
    return false
  }
}

/**
 * Authenticates a user if not already authenticated
 */
export async function connect(
  data: { user: any; dispatch?: AppDispatch } = { user: null },
  options: { canInitiateConnection?: Boolean } = { canInitiateConnection: true }
) {
  // Make sure we have a TezosToolkit instance
  if (!tezos) {
    console.log('TezosToolkit instance not found.')

    try {
      const { tezos: _tezos, node } = await createNewTezosWalletInstance()
      tezos = _tezos
      data?.dispatch(updateNodeInStore(node))
    } catch (error) {
      console.error(error)
      return false
    }
  }

  // Make sure we have a wallet instance
  if (!wallet) {
    console.log('BeaconWallet instance not found.')
    wallet = createNewBeaconWalletInstance()
  }

  const activeAccount = await wallet.client.getActiveAccount()
  let address: String
  if (!activeAccount) {
    console.log('No active account found')

    if (options?.canInitiateConnection === false) {
      signOut(auth)
      return false
    }

    try {
      const permissions = await wallet.client.requestPermissions({
        network: {
          type: CONFIG.NETWORK_TYPE as NetworkType,
        },
      })
      address = permissions.address
    } catch (error) {
      return false
    }
  } else {
    address = activeAccount.address
  }
  tezos.setWalletProvider(wallet)
  if (address !== data.user?.wallet) {
    const activeAccount = await wallet.client.getActiveAccount()

    if (!activeAccount) {
      throw new Error('Could not fetch active account')
    }

    // Airgap wallet gives hex encoded public key
    // we need to convert it into base58 encoded public key (edpk...)
    if (activeAccount.publicKey.length > 55) {
      activeAccount.publicKey = b58cencode(
        activeAccount.publicKey,
        new Uint8Array([13, 15, 37, 217])
      )
    }

    try {
      // The data to format
      const dappName = 'tezotopia.com'
      const ISO8601formatedTimestamp = new Date().toISOString()

      // The full string
      const formattedInput = [
        'Tezos Signed Message: Confirming identity on',
        dappName,
        'as',
        address,
        'at',
        ISO8601formatedTimestamp,
      ].join(' ')

      var payloadMessageHex = getMessageHex(formattedInput)

      // The payload to send to the wallet
      const payload = {
        signingType: SigningType.MICHELINE,
        payload: payloadMessageHex,
      }

      // The signing
      const signedPayload = await wallet.client.requestSignPayload(payload)

      // The signature
      var { signature } = signedPayload
    } catch (error) {
      await disconnectWallet()
      return
    }

    try {
      const authSync = httpsCallable(functions, 'auth-sync')
      const {
        data: { success, message, data },
      } = (await authSync({
        signature,
        pk: activeAccount.publicKey,
        message: payloadMessageHex,
      })) as {
        data: any
      }

      if (!success) {
        throw new Error(message)
      }

      const { token } = data
      await signInWithCustomToken(auth, token)
    } catch (error) {
      console.error(error)
      await disconnectWallet()
    }
  }
}

/**
 * Disconnects the wallet instance in window
 */
export async function disconnectWallet(options?: {
  supressNotifications?: Boolean
}): Promise<void> {
  try {
    await wallet?.clearActiveAccount()
    await signOut(auth)
    if (options?.supressNotifications !== true) {
      // TODO: Show notification
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * Truncates string from center
 */
export function truncateAddress(
  address: string,
  startLimit: number = 4,
  endLimit: number = 4,
  separator: string = '...'
) {
  if (!address) return ''
  if (address.length <= startLimit + endLimit + separator.length) return address

  return (
    address.substring(0, startLimit) +
    separator +
    address.substring(address.length - endLimit, address.length)
  )
}

export async function updateNode(node) {
  if (!(await isRPCNodeResponsive(node))) {
    return false
  }
  tezos = new TezosToolkit(node)
  attachContractLibrary(tezos)

  if (wallet) {
    tezos.setWalletProvider(wallet)
  }

  return true
}
