import { useEffect, useState } from 'react'
import Big from 'big.js'
import ReCAPTHCA from 'react-google-recaptcha-enterprise'

import { Data } from '@/hooks/data'
import useCrowdsaleContract from '@/hooks/contracts/crowdsale'
import { useAppSelector } from '@/store'

import './Hero.scss'

import diplomat from '@/assets/diplomat.png'
import arrow from '@/assets/carrot-down.svg'
import { httpsCallable } from 'firebase/functions'
import { functions } from '@/helpers/firebase'
import CONFIG from '@/helpers/config'
import { toast } from 'react-toastify'
import useAuth from '@/hooks/auth'

function Hero({ data, refetch }: { data: Data; refetch: Function }) {
  const [count, setCount] = useState<number>(0)
  const user = useAppSelector((state) => state.user)
  const { mint } = useCrowdsaleContract()
  const [mintButtonClicked, setMintButtonClicked] = useState<boolean>(false)
  const { connect, isConnecting } = useAuth()
  const [sale, setSale] = useState(null)
  const [mintLimit, setMintLimit] = useState(0)

  useEffect(() => {
    if (!data) {
      return
    }

    let timeout: NodeJS.Timeout

    function update() {
      const now = Date.now()
      if (new Date(data.publicsaleEndTime).getTime() < now) {
        sale !== 'ended' && setSale('ended')
      } else if (new Date(data.publicsaleStartTime).getTime() < now) {
        sale !== 'public' && setSale('public')
      } else if (new Date(data.presaleStartTime).getTime() < now) {
        sale !== 'presale' && setSale('presale')
      }

      timeout = setTimeout(update, 1000)
    }

    update()

    return () => clearInterval(timeout)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!data) {
      return
    }

    let max: number = 0

    if (sale === 'presale') {
      max = data.limit - data.minted
    } else if (sale === 'public') {
      max = data.maxMint - data.minted
    }

    const totalAvailable = data.totalSupply - data.totalMints

    max = Math.min(max, data.mintLimit, totalAvailable)

    if (max !== mintLimit) {
      setMintLimit(max)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sale])

  if (!data) {
    return null
  }

  function updateCount(value: number) {
    if (value < 0) {
      return
    }

    if (value > mintLimit) {
      return
    }

    setCount(value)
  }

  async function onMint(token: string) {
    setMintButtonClicked(false)

    if (token === null) {
      return
    }

    toast.loading('Processing your request', { toastId: 'diplomat-mint' })

    try {
      // Get signature from backend
      const getSignature = httpsCallable(functions, 'diplomat-mint')
      const res = await getSignature({ token, amount: count })
      const _data = res.data as { success: boolean; data: any; error: string }

      if (!_data.success) {
        throw new Error(_data.error)
      }

      // Smart contract call
      await mint(count, _data.data.signature, data.price)

      // Reset the count
      setCount(0)
    } catch (error) {
      toast.update('diplomat-mint', {
        render: error.message,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        isLoading: false,
        closeOnClick: true,
      })
    }

    refetch()
  }

  return (
    <div className="hero">
      <div className="left">
        <div className="title stripe-background">
          <h1>DIPLOMATS</h1>
          <h2>
            {data.totalSupply} respresentatives of Tezotopia will be unleashed
            on February 18, 2022.
          </h2>
        </div>
        {sale &&
          (!mintButtonClicked ? (
            <>
              <div className="minting">
                <p>
                  {sale === 'public'
                    ? 'PUBLIC SALE IS LIVE'
                    : sale === 'presale' && 'PRESALE IS LIVE'}
                </p>

                {sale !== 'ended' && (
                  <div className="action">
                    <div className="count">
                      <p>{count}</p>
                      {count < mintLimit && (
                        <img
                          className="arrow"
                          id="up"
                          src={arrow}
                          alt="increase amount"
                          onClick={() => updateCount(count + 1)}
                        />
                      )}
                      {count > 0 && (
                        <img
                          className="arrow"
                          id="down"
                          src={arrow}
                          alt="decrease amount"
                          onClick={() => updateCount(count - 1)}
                        />
                      )}
                    </div>

                    {user ? (
                      <button
                        disabled={count === 0}
                        onClick={() => setMintButtonClicked(true)}
                      >
                        MINT
                      </button>
                    ) : (
                      <button
                        disabled={isConnecting as boolean}
                        onClick={() => connect({ canInitiateConnection: true })}
                      >
                        sync
                      </button>
                    )}

                    {count > 0 && (
                      <p className="price">
                        {Big(data.price).div(1e6).times(count).toNumber()} ꜩ
                      </p>
                    )}
                  </div>
                )}
                <p className="message">{getMessage(data, user, sale)}</p>

                <div className="progress">
                  <div className="bar">
                    <div
                      className="filled"
                      style={{
                        width:
                          (sale === 'ended'
                            ? 1
                            : data.totalMints / data.totalSupply) *
                            100 +
                          '%',
                      }}
                    ></div>
                  </div>
                  <p className="count">
                    <span className="minted">
                      {sale === 'ended' ? data.totalSupply : data.totalMints}
                    </span>
                    <span className="total">/{data.totalSupply}</span>
                  </p>
                </div>
                <h3>
                  25K+ GIF stakers older than 30 days are eligible for 3%
                  discount
                </h3>
              </div>
            </>
          ) : (
            <ReCAPTHCA sitekey={CONFIG.CAPTCHA.SITE_KEY} onChange={onMint} />
          ))}
      </div>
      <div className="right">
        <img src={diplomat} alt="diplomat" />
      </div>
    </div>
  )
}

export default Hero

function getMessage(data: Data, user: any, sale: string) {
  if (sale === 'ended') {
    if (user === false) {
      return 'The sale has ended'
    }
    return `The sale has ended. You have minted ${data.minted} out of ${data.totalSupply}.`
  }

  if (sale === 'public') {
    if (user === false) {
      return 'connect wallet to mint.'
    }
    return `${data.minted} minted out of ${data.maxMint}.`
  }

  if (sale === 'presale') {
    if (user === false) {
      return 'Connect wallet to see whitelisting status.'
    }

    if (data.limit === 0) {
      return 'Sorry, you are not whitelisted for presale.'
    }

    return `You have ${data.limit} presale passes. ${data.minted} used.`
  }
}
