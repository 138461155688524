import { ToastContainer } from 'react-toastify'

import {
  Header,
  Sidebar,
  Hero,
  Details,
  ClassDistribution,
  Artist,
  Footer,
  Splash,
  Provenance,
  NodeSelector,
} from '@/components'

import useAuth from '@/hooks/auth'
import useData from '@/hooks/data'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const { isConnecting, connect, user } = useAuth()
  const { data, refetch } = useData()

  if (!data) {
    return null
  }

  return (
    <>
      <Splash submitting={isConnecting} user={user} onSyncClick={connect} />
      <NodeSelector />
      <Header />
      <main>
        <Sidebar id="left" />
        <Hero data={data} refetch={refetch} />
        <Details data={data} />
        <ClassDistribution />
        <Artist />
        <Provenance data={data} />
        <Footer />
        <Sidebar id="right" />
      </main>
      <ToastContainer
        theme="dark"
        pauseOnHover
        newestOnTop
        position="bottom-right"
        draggable
        autoClose={5000}
        closeOnClick
        closeButton
      />
    </>
  )
}

export default App
